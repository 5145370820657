import { connectFunctionsEmulator, getFunctions, httpsCallable } from "@firebase/functions";
import { useCallback, useState } from "react";
import { CLIENT_CONSOLE } from "./constants/platform";
import { useDeviceId, useIsStagingEnv } from "./contexts/InstallationContext";

export default function useOperationFunction() {
  const installId = useDeviceId();
  const isStaging = useIsStagingEnv();
  const [loading, setLoading] = useState();

  const onSubmit = useCallback(
    (group, type, data) => {
      const functions = getFunctions();

      if (process.env.NODE_ENV === "development") {
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);
      }

      setLoading(data || {});
      return httpsCallable(
        functions,
        isStaging ? "stagingOperationCreate" : "operationCreate"
      )({
        group,
        type,
        data: data || {},
        client: CLIENT_CONSOLE,
        installId,
      }).finally(() => setLoading(null));
    },
    [installId]
  );

  return {
    onSubmit,
    request: loading,
    loading: Boolean(loading),
  };
}
