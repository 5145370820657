import { Button } from "antd";
import React from "react";
import { useIsStagingEnv, useOperator } from "./contexts/InstallationContext";
import useOperationFunction from "./useOperationFunction";

export default function NewConsoleButton({ pathname, size, children }) {
  const { onSubmit, loading } = useOperationFunction();
  const operator = useOperator();
  const staging = useIsStagingEnv();

  return (
    <Button
      loading={loading}
      size={size}
      onClick={() => {
        onSubmit("admin", "upgrade_auth", { uid: operator.id }).then((response) => {
          if (response?.data?.token) {
            window.open(`https://${staging?`console-git-master-easybus.vercel.app`:`new.console.easybizcloud.com`}/en/${pathname}?token=${response?.data?.token}`);
          }
        });
      }}
    >
      {children || (`${pathname}`.includes("settings") ? `Take me to new setting page` : `Take me to 3.0 console`)}
    </Button>
  );
}
