import { AccountBookOutlined, AppstoreFilled, AreaChartOutlined, AuditOutlined, BarChartOutlined, BellOutlined, BoldOutlined, BorderlessTableOutlined, BranchesOutlined, CalculatorOutlined, CalendarOutlined, CarFilled, CarOutlined, CarryOutOutlined, CheckSquareOutlined, ClockCircleOutlined, CloudOutlined, ClusterOutlined, CoffeeOutlined, ContactsOutlined, ContainerOutlined, CreditCardOutlined, CrownOutlined, DeleteOutlined, DesktopOutlined, DollarOutlined, EnvironmentOutlined, FileDoneOutlined, FilePdfOutlined, FileSearchOutlined, FundViewOutlined, GiftFilled, GiftOutlined, HddOutlined, HistoryOutlined, HourglassOutlined, IdcardFilled, KeyOutlined, LinkOutlined, MessageOutlined, MobileFilled, MobileOutlined, MoneyCollectOutlined, NotificationOutlined, NumberOutlined, PayCircleOutlined, PlusOutlined, PrinterOutlined, ProjectOutlined, QuestionCircleOutlined, RollbackOutlined, SafetyCertificateOutlined, SafetyOutlined, SearchOutlined, SettingOutlined, ShareAltOutlined, ShopFilled, ShopOutlined, ShoppingCartOutlined, ShoppingOutlined, SisternodeOutlined, SkinOutlined, StarOutlined, SyncOutlined, TableOutlined, TagOutlined, TagsOutlined, TeamOutlined, ToolFilled, TransactionOutlined, UserOutlined, UserSwitchOutlined, WalletOutlined, WarningOutlined } from '@ant-design/icons';
import React, { useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import BusinessAvatar from '../components/BusinessAvatar';
import { BUSINESS_SECTOR_AIRCON_SERVICE, BUSINESS_SECTOR_ALTERATION, BUSINESS_SECTOR_BAG_SERVICE, BUSINESS_SECTOR_CLEANING, BUSINESS_SECTOR_HANDYMAN, BUSINESS_SECTOR_LAUNDRY, BUSINESS_SECTOR_PEST_CONTROL, isPUDBusiness } from '../constants/business';
import { PERMISSION_DATA_ANALYSIS, PERMISSION_DATA_CUSTOMERS, PERMISSION_DATA_NEW_ONLINE_ORDER, PERMISSION_DATA_ORDERS, PERMISSION_DATA_SALES, PERMISSION_DATA_SEARCH_ORDERS, PERMISSION_LOYALTY_PROGRAMS, PERMISSION_MARKETING, PERMISSION_REPORTS, PERMISSION_SETTING_APP, PERMISSION_SETTING_BILLING, PERMISSION_SETTING_BUSINESS, PERMISSION_SETTING_NOTIICATION_CENTER, PERMISSION_SETTING_OPERATION, PERMISSION_SETTING_PLANT, PERMISSION_SETTING_STORE, PERMISSION_SETTING_SYSTEM, PERMISSION_SETTING_WORKFLOW, PERMISSION_WORKFLOW_CASE_REPORTS, PERMISSION_WORKFLOW_JOB_SCHEDULE, PERMISSION_WORKFLOW_PLANT_BILLING, PERMISSION_WORKFLOW_PRODUCTION_MONITOR, PERMISSION_WORKFLOW_REVIEWS } from '../constants/permission';
import { EASYBIZ_PRODUCT_ID_ONLINE, EASYBIZ_PRODUCT_ID_POS } from '../constants/platform';
import safeSearchRegExp from '../utils/safeSearchRegExp';
import { useAccess } from './AuthenticationContext';
import { useApps, useBusinesses, useBusinessSector, useHavePlant, useStores } from './RealmContext';

const Pagecontext = React.createContext();

export function PageProvider({ children }) {
  const havePlant = useHavePlant();
  const accessSales = useAccess(PERMISSION_DATA_SALES);
  const accessOrders = useAccess(PERMISSION_DATA_ORDERS);
  const limitToSearch = useAccess(PERMISSION_DATA_SEARCH_ORDERS);
  const accessPlantBilling = useAccess(PERMISSION_WORKFLOW_PLANT_BILLING);
  const accessPlantSetting = useAccess(PERMISSION_SETTING_PLANT);
  const accessAppSetting = useAccess(PERMISSION_SETTING_APP);
  const accessStoreSetting = useAccess(PERMISSION_SETTING_STORE);
  const accessAnalysis = useAccess(PERMISSION_DATA_ANALYSIS);
  const accessBilling = useAccess(PERMISSION_SETTING_BILLING);
  const accsssProductionMonitor = useAccess(PERMISSION_WORKFLOW_PRODUCTION_MONITOR);
  const accessReview = useAccess(PERMISSION_WORKFLOW_REVIEWS);
  const accessCaseReport = useAccess(PERMISSION_WORKFLOW_CASE_REPORTS);
  const accessOperationSetting = useAccess(PERMISSION_SETTING_OPERATION);
  const accessBusiness = useAccess(PERMISSION_SETTING_BUSINESS);
  const apps = useApps();
  const stores = useStores();
  const businesses = useBusinesses();
  const accessLogistic = useAccess(PERMISSION_WORKFLOW_JOB_SCHEDULE);
  const accessReport = useAccess(PERMISSION_REPORTS);
  const accessCustomer = useAccess(PERMISSION_DATA_CUSTOMERS);
  const accessMarketing = useAccess(PERMISSION_MARKETING);
  const accessLoyalty = useAccess(PERMISSION_LOYALTY_PROGRAMS);
  const accessTeammates = useAccess(PERMISSION_SETTING_WORKFLOW);
  const accessSystemSetting = useAccess(PERMISSION_SETTING_SYSTEM);
  const accessNotiicationCenter = useAccess(PERMISSION_SETTING_NOTIICATION_CENTER);
  const accessNewOrder = useAccess(PERMISSION_DATA_NEW_ONLINE_ORDER);
  const intl = useIntl();

  const context = {
    ...havePlant && (accessOrders || accessPlantBilling || accessPlantSetting) && {
      plant: {
        key: 'plant',
        label: intl.formatMessage({ defaultMessage: 'Plant' }),
        icon: <ClusterOutlined />,
        menuItems: [
          ...(accessSales ? [{ key: `sales`, icon: <AreaChartOutlined />, label: intl.formatMessage({ defaultMessage: 'Sales' }) }] : []),
          ...(accessOrders ? [{ key: `orders`, icon: <SearchOutlined />, label: intl.formatMessage({ defaultMessage: 'Search' }) }] : []),
          { key: `check-out`, icon: <CarryOutOutlined />, label: intl.formatMessage({ defaultMessage: 'Plant check-out' }) },
          { key: `invoicing`, icon: <AccountBookOutlined />, label: intl.formatMessage({ defaultMessage: 'Monthly billing' }) },
          ...accessPlantSetting ? [
            {
              label: intl.formatMessage({ defaultMessage: 'Settings' }),
              type: 'group',
              children: [
                { key: `sku`, icon: <SkinOutlined />, label: intl.formatMessage({ defaultMessage: 'SKUs' }) },
                { key: `price-lists`, icon: <DollarOutlined />, label: intl.formatMessage({ defaultMessage: 'Price lists' }) },
                { key: `order-flow`, icon: <BranchesOutlined />, label: intl.formatMessage({ defaultMessage: 'Order flow' }) },
                { key: `agents`, icon: <ContactsOutlined />, label: intl.formatMessage({ defaultMessage: 'Agents' }) },
                { key: `billing`, icon: <FilePdfOutlined />, label: intl.formatMessage({ defaultMessage: 'Breakdown templates' }) },
                { key: `discount-surcharge`, icon: <CalculatorOutlined />, label: intl.formatMessage({ defaultMessage: 'Surcharge/Discount' }) }
              ]
            }
          ] : []
        ]
      }
    },
    ...(accessAppSetting || accessBilling || accessAnalysis || accessSales || accessOrders || accessNewOrder) && {
      app: {
        key: 'app',
        icon: <MobileFilled />,
        label: intl.formatMessage({ defaultMessage: 'App' }),
        menuItems: [
          ...(accessSales ? [{ key: `sales`, icon: <AreaChartOutlined />, label: intl.formatMessage({ defaultMessage: 'Sales' }) }] : []),
          ...accessAnalysis ? [
            { key: `daily-sales`, icon: <BarChartOutlined />, label: intl.formatMessage({ defaultMessage: 'Daily orders' }) },
            { key: `active-user`, label: intl.formatMessage({ defaultMessage: 'Daily active user' }), icon: <MobileOutlined /> },
            { key: `leads`, label: intl.formatMessage({ defaultMessage: 'Leads' }), icon: <UserSwitchOutlined /> }
          ] : [],
          ...accessAnalysis ? [
            { key: `livechat`, label: intl.formatMessage({ defaultMessage: 'Live chat' }), icon: <MessageOutlined /> },
            { key: `referrals`, label: intl.formatMessage({ defaultMessage: 'Referral' }), icon: <ShareAltOutlined /> }
          ] : [],
          ...(accessOrders && !limitToSearch) ? [
            { key: `recurrings`, label: intl.formatMessage({ defaultMessage: 'Recurring' }), icon: <SyncOutlined /> }
          ] : [],
          ...(accessOrders || accessNewOrder) ? [
            {
              label: intl.formatMessage({ defaultMessage: 'Orders' }),
              type: 'group',
              children: [
                ...accessNewOrder ? [
                  { key: `new`, label: intl.formatMessage({ defaultMessage: 'New order' }), icon: <PlusOutlined /> },
                ] : [],
                ...(accessOrders ? [{ key: `orders`, icon: <SearchOutlined />, label: intl.formatMessage({ defaultMessage: 'Search' }) }] : []),
                ...(accessOrders && !limitToSearch) ? [{ key: `calendar`, icon: <CalendarOutlined />, label: intl.formatMessage({ defaultMessage: 'Calendar' }) }] : []
              ]
            }
          ] : [],
          ...(accessAppSetting || accessBilling) ? [
            {
              label: intl.formatMessage({ defaultMessage: 'App settings' }),
              type: 'group',
              children: [
                ...(apps || []).map(app => ({
                  key: app.id,
                  label: app.title,
                  icon: <BoldOutlined />
                })),
                ...accessBilling ? [
                  { key: `/dashboard/subscribe/${EASYBIZ_PRODUCT_ID_ONLINE}`, icon: <PlusOutlined />, label: intl.formatMessage({ defaultMessage: 'Add New App' }) }
                ] : []
              ]
            }
          ] : []
        ]
      }
    },
    ...(accessLogistic) && {
      logistic: {
        key: 'logistic',
        icon: <CarFilled />,
        label: intl.formatMessage({ defaultMessage: 'Logistic' }),
        menuItems: [
          { key: 'dashboard', icon: <AreaChartOutlined />, label: intl.formatMessage({ defaultMessage: 'Dashboard' }) },
          {
            label: intl.formatMessage({ defaultMessage: 'Operation' }),
            type: 'group',
            children: [
              { key: 'overview', icon: <DesktopOutlined />, label: intl.formatMessage({ defaultMessage: 'Overview' }) },
              { key: 'worktime', icon: <TableOutlined />, label: intl.formatMessage({ defaultMessage: 'Worktime' }) },
              { key: 'map', icon: <EnvironmentOutlined />, label: intl.formatMessage({ defaultMessage: 'Map View' }) }
            ]
          },
          {
            label: intl.formatMessage({ defaultMessage: 'History' }),
            type: 'group',
            children: [
              { key: 'calendar', icon: <CalendarOutlined />, label: intl.formatMessage({ defaultMessage: 'Calendar' }) },
              { key: 'jobs', icon: <TagOutlined />, label: intl.formatMessage({ defaultMessage: 'Job history' }) },
              { key: 'trips', icon: <CarOutlined />, label: intl.formatMessage({ defaultMessage: 'Trip records' }) }
            ]
          }
        ]
      }
    },
    ...(accessStoreSetting || accessAnalysis || accessBilling || accessSales || accessOrders) && {
      store: {
        key: 'store',
        icon: <ShopFilled />,
        label: intl.formatMessage({ defaultMessage: 'Store' }),
        menuItems: [
          ...(accessSales ? [{ key: `sales`, icon: <AreaChartOutlined />, label: intl.formatMessage({ defaultMessage: 'Sales' }) }] : []),
          ...(accessOrders ? [{ key: `orders`, icon: <SearchOutlined />, label: intl.formatMessage({ defaultMessage: 'Search' }) }] : []),
          ...((accessOrders && !limitToSearch) ? [{ key: `calendar`, icon: <CalendarOutlined />, label: intl.formatMessage({ defaultMessage: 'Calendar' }) }] : []),
          ...accessAnalysis ? [
            { key: `daily-sales`, icon: <BarChartOutlined />, label: intl.formatMessage({ defaultMessage: 'Daily orders' }) },
            { key: `staff_hours`, label: intl.formatMessage({ defaultMessage: 'Staff hours' }), icon: <ClockCircleOutlined /> },
            { key: `popular_times`, label: intl.formatMessage({ defaultMessage: 'Popular times' }), icon: <BarChartOutlined /> },
            { key: `cash_drawers`, label: intl.formatMessage({ defaultMessage: 'Cash drawers' }), icon: <ContainerOutlined /> }
          ] : [],
          ...accessStoreSetting ? [
            { key: `settings`, label: intl.formatMessage({ defaultMessage: 'POS settings' }), icon: <DesktopOutlined /> }
          ] : [],
          ...(accessStoreSetting || accessBilling) ? [
            {
              label: intl.formatMessage({ defaultMessage: 'Store settings' }),
              type: 'group',
              children: [
                ...(stores || []).map(store => ({ key: store.id, label: store.title, icon: <ShopOutlined /> })),
              ]
            }
          ] : []
        ]
      }
    },
    ...(accsssProductionMonitor || accessReview || accessCaseReport) && {
      operation: {
        key: 'operation',
        icon: <ToolFilled />,
        label: intl.formatMessage({ defaultMessage: 'Operation' }),
        menuItems: [
          { key: `dashboard`, icon: <AreaChartOutlined />, label: intl.formatMessage({ defaultMessage: 'Dashboard' }) },
          ...(accessReview ? [
            {
              label: intl.formatMessage({ defaultMessage: 'Customer feedback' }),
              type: 'group',
              children: [
                { key: `reviews`, icon: <StarOutlined />, label: intl.formatMessage({ defaultMessage: 'Reviews' }) },
                { key: `feedback-settings`, icon: <SettingOutlined />, label: intl.formatMessage({ defaultMessage: 'Feedback settings' }) }
              ]
            }
          ] : []),
          ...(accsssProductionMonitor || accessCaseReport || accessOperationSetting) ? [
            {
              label: intl.formatMessage({ defaultMessage: 'Production' }),
              type: 'group',
              children: [
                ...(accsssProductionMonitor ? [{ key: `production-monitor`, icon: <FundViewOutlined />, label: intl.formatMessage({ defaultMessage: 'Production monitor' }) }] : []),
                ...(accessOperationSetting ? [{ key: `business-agent`, icon: <ContactsOutlined />, label: intl.formatMessage({ defaultMessage: 'Business agent' }) }] : []),
                ...(accessCaseReport ? [{ key: `reports`, icon: <AuditOutlined />, label: intl.formatMessage({ defaultMessage: 'Report cases' }) }] : []),
                ...(accessOperationSetting ? [{ key: `production-setting`, icon: <SettingOutlined />, label: intl.formatMessage({ defaultMessage: 'Production settings' }) }] : []),
                ...(accessOperationSetting ? [{ key: `collection-points`, icon: <HddOutlined />, label: intl.formatMessage({ defaultMessage: 'Collection points' }) }] : [])
              ]
            }
          ] : []
        ]
      }
    },
    ...accessReport && {
      report: {
        key: 'report',
        icon: <FileSearchOutlined />,
        label: intl.formatMessage({ defaultMessage: 'Report' }),
        menuItems: [
          { key: `sales-statistic`, icon: <DollarOutlined />, label: intl.formatMessage({ defaultMessage: 'Sales statistic' }) },
          { key: `sales-brekdowns`, icon: <ProjectOutlined />, label: intl.formatMessage({ defaultMessage: 'Sales breakdown' }) },
          { key: `orders`, icon: <NumberOutlined />, label: intl.formatMessage({ defaultMessage: 'Orders' }) },
          { key: `pending_confirmation`, icon: <AccountBookOutlined />, label: intl.formatMessage({ defaultMessage: 'Pending confirm' }) },
          { key: `outstandings`, icon: <WarningOutlined />, label: intl.formatMessage({ defaultMessage: 'Outstandings' }) },
          { key: `payments`, icon: <CreditCardOutlined />, label: intl.formatMessage({ defaultMessage: 'Payments' }) },
          { key: `refunds`, icon: <TransactionOutlined />, label: intl.formatMessage({ defaultMessage: 'Refunds' }) },
          { key: `top-ups`, icon: <WalletOutlined />, label: intl.formatMessage({ defaultMessage: 'Top Ups' }) },
          { key: `sales-returns`, icon: <RollbackOutlined />, label: intl.formatMessage({ defaultMessage: 'Sales returns' }) },
          { key: `cancellations`, icon: <DeleteOutlined />, label: intl.formatMessage({ defaultMessage: 'Cancellations' }) },
          { key: `business-agents`, icon: <ContactsOutlined />, label: intl.formatMessage({ defaultMessage: 'Business agents' }) },
          { key: `pending-discharge`, icon: <TagOutlined />, label: intl.formatMessage({ defaultMessage: 'Pending discharge' }) },
          { key: `customer-spendings`, icon: <TeamOutlined />, label: intl.formatMessage({ defaultMessage: 'Customer' }) }
        ]
      }
    },
    ...accessBusiness && {
      business: {
        key: 'business',
        icon: <AppstoreFilled />,
        label: intl.formatMessage({ defaultMessage: 'Business' }),
        menuItems: [
          {
            key: 'business',
            label: intl.formatMessage({ defaultMessage: 'Business ({count})' }, { count: businesses?.length }),
            type: 'group',
            children: (businesses || []).map(business => {
              return {
                key: business.id,
                label: <span className='padding-left-half'>{business.title}</span>,
                icon: <BusinessAvatar size='small' shape='square' service={business} />
              };
            })
          },
          {
            label: intl.formatMessage({ defaultMessage: 'General settings' }),
            type: 'group',
            children: [
              { key: `discount-surcharge`, label: intl.formatMessage({ defaultMessage: 'Surcharge/Discount' }), icon: <DollarOutlined /> },
              { key: `off-days`, label: intl.formatMessage({ defaultMessage: 'Off Days' }), icon: <CoffeeOutlined /> }
            ]
          }
        ]
      }
    },
    ...accessCustomer && {
      customer: {
        key: 'customer',
        icon: <TeamOutlined />,
        label: intl.formatMessage({ defaultMessage: 'Customer' })
      }
    },
    ...accessMarketing && {
      marketing: {
        key: 'marketing',
        icon: <GiftFilled />,
        label: intl.formatMessage({ defaultMessage: 'Marketing' }),
        menuItems: [
          {
            label: intl.formatMessage({ defaultMessage: 'Online marketing' }),
            type: 'group',
            children: [
              { key: `channels`, label: intl.formatMessage({ defaultMessage: 'Marketing channels' }), icon: <LinkOutlined /> },
              // { key: `campaign`, label: intl.formatMessage({defaultMessage: 'Campaign'}), icon: <CarryOutOutlined/> },
              { key: `articles`, label: intl.formatMessage({ defaultMessage: 'Articles' }), icon: <FileDoneOutlined /> }
            ]
          },
          {
            label: intl.formatMessage({ defaultMessage: 'General marketing' }),
            type: 'group',
            children: [
              { key: `promotions`, label: intl.formatMessage({ defaultMessage: 'Promotions' }), icon: <ShoppingOutlined /> },
              { key: `promocode`, label: intl.formatMessage({ defaultMessage: 'Promo code' }), icon: <BorderlessTableOutlined /> },
              { key: `batch_voucher`, label: intl.formatMessage({ defaultMessage: 'Batch voucher' }), icon: <TagsOutlined /> },
              { key: `birthday_promo`, label: intl.formatMessage({ defaultMessage: 'Birthday promo' }), icon: <GiftOutlined /> }
            ]
          }
        ]
      }
    },
    ...accessLoyalty && {
      loyalty: {
        key: 'loyalty',
        icon: <IdcardFilled />,
        label: intl.formatMessage({ defaultMessage: 'Loyalty' }),
        menuItems: [
          { key: `dashboard`, icon: <AreaChartOutlined />, label: intl.formatMessage({ defaultMessage: 'Dashboard' }) },
          {
            label: intl.formatMessage({ defaultMessage: 'Paid program' }),
            sub: true,
            icon: <CrownOutlined />,
            children: [
              { key: `member_customers`, icon: <UserOutlined />, label: intl.formatMessage({ defaultMessage: 'Members' }) },
              { key: `member_history`, icon: <HistoryOutlined />, label: intl.formatMessage({ defaultMessage: 'Member history' }) },
              { key: `member_settings`, icon: <SettingOutlined />, label: intl.formatMessage({ defaultMessage: 'Settings' }) }
            ]
          },
          {
            label: intl.formatMessage({ defaultMessage: 'Top-up program' }),
            sub: true,
            icon: <WalletOutlined />,
            children: [
              { key: `crdit_balance`, icon: <WalletOutlined />, label: intl.formatMessage({ defaultMessage: 'Customer credits' }) },
              { key: `crdit_history`, icon: <HistoryOutlined />, label: intl.formatMessage({ defaultMessage: 'Top-up history' }) },
              { key: `crdit_transactions`, icon: <FileSearchOutlined />, label: intl.formatMessage({ defaultMessage: 'Credit transactions' }) },
              { key: `crdit_settings`, icon: <SettingOutlined />, label: intl.formatMessage({ defaultMessage: 'Settings' }) }
            ]
          },
          {
            label: intl.formatMessage({ defaultMessage: 'Points program' }),
            sub: true,
            icon: <GiftOutlined />,
            children: [
              { key: `points_balance`, icon: <GiftOutlined />, label: intl.formatMessage({ defaultMessage: 'Customer points' }) },
              { key: `points_history`, icon: <HistoryOutlined />, label: intl.formatMessage({ defaultMessage: 'Points transactions' }) },
              { key: `points_settings`, icon: <SettingOutlined />, label: intl.formatMessage({ defaultMessage: 'Settings' }) }
            ]
          }
        ]
      }
    },
    profile: {
      key: 'profile',
      icon: <UserOutlined />,
      label: intl.formatMessage({ defaultMessage: 'My profile' })
    },
    sysNotice: {
      key: 'system_notice',
      icon: <BellOutlined />,
      label: intl.formatMessage({ defaultMessage: 'System notice' })
    },
    ...accessBilling && {
      billing: {
        key: 'billing',
        icon: <CreditCardOutlined />,
        label: intl.formatMessage({ defaultMessage: 'Billing' }),
        menuItems: [
          { key: `subscription`, icon: <CloudOutlined />, label: intl.formatMessage({ defaultMessage: 'Subscription' }) },
          { key: `invoices`, icon: <FilePdfOutlined />, label: intl.formatMessage({ defaultMessage: 'Invoices' }) }
        ]
      },
    },
    ...accessTeammates && {
      accounts: {
        key: 'accounts',
        icon: <TeamOutlined />,
        label: intl.formatMessage({ defaultMessage: 'Accounts' })
      },
      roles: {
        key: 'roles',
        icon: <KeyOutlined />,
        label: intl.formatMessage({ defaultMessage: 'Roles' })
      }
    },
    ...accessNotiicationCenter && {
      notification: {
        key: 'notifications',
        icon: <NotificationOutlined />,
        label: intl.formatMessage({ defaultMessage: 'Notification' })
      }
    },
    ...accessSystemSetting && {
      printing: {
        key: 'printing',
        icon: <PrinterOutlined />,
        label: intl.formatMessage({ defaultMessage: 'Printing' })
      },
      security: {
        key: 'security',
        icon: <SafetyCertificateOutlined />,
        label: intl.formatMessage({ defaultMessage: 'Security' })
      },
      paymentMethods: {
        key: 'payment_methods',
        icon: <MoneyCollectOutlined />,
        label: intl.formatMessage({ defaultMessage: 'Payment methods' })
      },
      sysSetting: {
        key: 'settings',
        icon: <SettingOutlined />,
        label: intl.formatMessage({ defaultMessage: 'General' })
      }
    }
  };

  return (
    <Pagecontext.Provider value={context}>
      {children}
    </Pagecontext.Provider>
  );
}

export const useBusinessPage = (serviceId) => {
  const sector = useBusinessSector(serviceId);
  const pudBusiness = isPUDBusiness(sector);
  const bagAlteration = [BUSINESS_SECTOR_BAG_SERVICE, BUSINESS_SECTOR_ALTERATION].indexOf(sector) >= 0;
  const intl = useIntl();

  return useMemo(() => {
    let businessMenus = [];

    switch (sector) {
      case BUSINESS_SECTOR_LAUNDRY:
        businessMenus = [
          { key: `pricing`, exact: true, icon: <DollarOutlined />, label: <FormattedMessage defaultMessage='Pricing' /> },
          { key: `service-types`, icon: <CheckSquareOutlined />, label: <FormattedMessage defaultMessage='Service types' /> }
        ];
        break;
      case BUSINESS_SECTOR_BAG_SERVICE:
      case BUSINESS_SECTOR_ALTERATION:
        businessMenus = [
          { key: `service-types`, icon: <CheckSquareOutlined />, label: <FormattedMessage defaultMessage='Service types' /> },
          { key: `products`, icon: <ShoppingOutlined />, label: <FormattedMessage defaultMessage='Craft/Products' /> }
        ];
        break;
      case BUSINESS_SECTOR_AIRCON_SERVICE:
      case BUSINESS_SECTOR_HANDYMAN:
      case BUSINESS_SECTOR_CLEANING:
      case BUSINESS_SECTOR_PEST_CONTROL:
        businessMenus = [
          { key: `service-types`, icon: <CheckSquareOutlined />, label: <FormattedMessage defaultMessage='Service types' /> }
        ];
        break;
    }

    return [
      ...businessMenus,
      ...pudBusiness ? [
        {
          label: intl.formatMessage({ defaultMessage: 'Store POS order' }),
          icon: <ShopOutlined />,
          children: [
            ...(pudBusiness ? [
              { key: `store-collection`, icon: <HourglassOutlined />, label: intl.formatMessage({ defaultMessage: 'Collection options' }) }
            ] : []),
            ...(bagAlteration ? [
              { key: `store-lineitem-steps`, icon: <SisternodeOutlined />, label: intl.formatMessage({ defaultMessage: 'Add line-item steps' }) }
            ] : []),
            { key: `store-minimum`, icon: <PayCircleOutlined />, label: intl.formatMessage({ defaultMessage: 'Minimum order' }) },
          ]
        }
      ] : [],
      {
        label: intl.formatMessage({ defaultMessage: 'Online booking' }),
        icon: <MobileOutlined />,
        children: [
          { key: `booking-area`, icon: <EnvironmentOutlined />, label: intl.formatMessage({ defaultMessage: 'Servicing area' }) },
          { key: `booking-time`, icon: <CalendarOutlined />, label: intl.formatMessage({ defaultMessage: 'Time slot' }) },
          ...pudBusiness ? [
            { key: `booking-delivery`, icon: <HourglassOutlined />, label: intl.formatMessage({ defaultMessage: 'Delivery options' }) }
          ] : [],
          { key: `booking-steps`, icon: <SisternodeOutlined />, label: intl.formatMessage({ defaultMessage: 'Booking steps' }) },
          { key: `booking-faq`, icon: <QuestionCircleOutlined />, label: intl.formatMessage({ defaultMessage: 'FAQ/Guide' }) },
        ]
      },
      { key: `logistic-control`, icon: <CarOutlined />, label: intl.formatMessage({ defaultMessage: 'Logistic control' }) },
      { key: `profile`, icon: <BoldOutlined />, label: intl.formatMessage({ defaultMessage: 'Business profile' }) },
      {
        label: intl.formatMessage({ defaultMessage: 'Terms & Conditions' }),
        icon: <SafetyOutlined />,
        children: [
          { key: `tc_printing`, icon: <PrinterOutlined />, label: intl.formatMessage({ defaultMessage: 'For receipt footer' }) },
          { key: `tc_html`, icon: <MobileOutlined />, label: intl.formatMessage({ defaultMessage: 'For App display' }) },
        ]
      },
      ...(pudBusiness ? [
        { key: `addons`, icon: <ShoppingCartOutlined />, label: intl.formatMessage({ defaultMessage: 'Add-ons' }) },
        { key: `accessories`, icon: <TagsOutlined />, label: intl.formatMessage({ defaultMessage: 'Accessories' }) }
      ] : []),
      {
        type: 'group',
        label: intl.formatMessage({ defaultMessage: 'Close business' }),
        children: [
          { key: `delete`, icon: <DeleteOutlined />, label: intl.formatMessage({ defaultMessage: 'Delete business' }), danger: true }
        ]
      }
    ];
  }, [serviceId]);
}

export const usePages = () => useContext(Pagecontext);

export const useSearchPages = (search) => {
  const pages = useContext(Pagecontext);

  if (!search) return [];

  const regX = safeSearchRegExp(search);

  return [].concat.apply([], Object.values(pages).map(({ menuItems, ...nav }) => {
    if (Array.isArray(menuItems)) {
      return [].concat.apply([], menuItems.map(({ children, ...page }) => {
        if (Array.isArray(children)) {
          return [].concat.apply([], children.map(({ children, ...subPage }) => {
            if (Array.isArray(children)) {
              return children.filter(menu => menu.key && regX.test(menu.label)).map(menu => {
                return [[nav, page, subPage, menu].filter(data => data.key)];
              });
            } else {
              return (subPage.key && regX.test(subPage.label)) ? [[nav, page, subPage].filter(data => data.key)] : [];
            }
          }));
        } else {
          return (page.key && regX.test(page.label)) ? [[nav, page].filter(data => data.key)] : [];
        }
      }));
    } else {
      return (nav.key && regX.test(nav.label)) ? [[nav]] : [];
    }
  }));
}